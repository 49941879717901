<template>
  <v-row>
    <v-icon color="success">mdi-printer-outline</v-icon>
    <v-btn
      text
      color="deep-purple accent-5"
      dark
      small
      class="text-decoration-underline pl-0"
      @click="input_other_data()"
      >Appointment</v-btn
    >
    <v-dialog v-model="json_dialog" max-width="500px" persistent>
      <v-card flat width="500px">
        <v-sheet
          color="deep-purple accent-4"
          class="pt-2 d-flex justify-center"
          height="40px"
        >
          <h3 class="white--text">Please enter required information</h3>
        </v-sheet>
        <div>
          <v-col cols="10" offset="1" class="mt-6">
            <div>
              <v-row>
                <v-col>
                  <v-text-field
                    class="body-2 rounded-xl"
                    hide-details=""
                    dense
                    label="Name of Lupon"
                    placeholder="Name of Lupon"
                    v-model="other_data.lupon_name"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    class="body-2 rounded-xl"
                    hide-details=""
                    dense
                    label="Address"
                    placeholder="Address"
                    v-model="other_data.address"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    type="date"
                    class="body-2 mb-4"
                    v-model="other_data.date"
                    label="Date of Appointment"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    class="body-2 rounded-xl"
                    hide-details=""
                    dense
                    label="Secretary Name"
                    placeholder="Secretary Name"
                    v-model="other_data.secretary_name"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="deep-purple lighten-4"
            @click="json_dialog = false"
          >
            cancel
          </v-btn>
          <v-btn
            small
            width="70px"
            color="deep-purple accent-4"
            dark
            @click="print()"
          >
            proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      date: null,
      json_dialog: false,
      other_data: {
        lupon_name: "",
        address: "",
        date: "",
        secretary_name: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      print_url: "auth/default_url",
      default_prescribed_url: "auth/default_prescribed_url",
      language: "auth/language",
    }),
  },
  methods: {
    input_other_data() {
      this.json_dialog = true;
    },
    print() {
      this.other_data.date = moment(this.other_data.date).format("YYYY-MM-DD");

      var complaint_url =
        this.default_prescribed_url +
        this.language +
        "/appointment/" +
        this.user.id +
        "/" +
        JSON.stringify(this.other_data);
      window.open(complaint_url);
    },
  },
};
</script>

<style></style>
